import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #18216d;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #18216d;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
    .maxImageheight{
        max-height: 400px;
        box-shadow: rgba(23, 31, 114, 0.2) 0px 16px 30px;
    }
    .subheading{
        font-size: 2rem;
    }
    .p-verticle{
        padding-block: 3.5rem;
    }
    .question-list{
        padding-left: 0px;
    }
    .question-list li{
        list-style: none;
        margin-bottom: 1.5rem;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        flex-direction: column;
    }
    .question-list li strong{
        margin-bottom: 1rem;
        font-size: 24px;
    }
    .pt-4{
        padding-top: 2.5rem;
    }
    .sectionheading{
        font-size: 15px;
    line-height: 1rem;
    padding: 0.5rem 0px;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
    font-family: "Motiva Sans Light", sans-serif;
    }
    .sectionparagraph{
        font-size: 13px;
         color: rgb(24, 33, 109);
         line-height: 1.41;
    }
    .Hero-image{
        max-width: 100%;
        max-height: 700px;
        border: 1px solid #000;
        border-radius: 40px;
        object-fit: none;
        width: 100%;
    }
    .side-image{
        max-width: 100%;
        border-radius: 40px;
        border: 1px solid #000;
        max-height: 550px;
        object-fit: contain;
    }
    .art{
        max-height: 310px;
    }
`;
