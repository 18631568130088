import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { CopyOutlined } from '@ant-design/icons';
import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import { Space, Typography } from 'antd';


interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px"  className={''}/>
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col md={24}>
            <div style={
              {
                textAlign:'center',
                fontSize:'18px',
                fontWeight:'bold'
              }
            }>
        All rights reserved by HoloTask ©.
        Design by Metaxols.
      </div>
            </Col>
          </Row>
         
        </Container>
      </FooterSection>
      
    </>
  );
};

export default withTranslation()(Footer);
